<template>
    <p>Register for the event here</p>
</template>

<script>

    export default {

        props: {

            event: {
                required: true
            }
        }
    }

</script>